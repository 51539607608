import api from "./index";

export default {
  async emailSignUp(props) {
    try {
      const response = await api.post("/subscribe", props);
      return response.data;
    } catch (error) {
      return false;
    }
  },
  async smsSignUp(props) {
    try {
      return await api.post("/subscribe_to_sms", props);
    } catch (error) {
      return false;
    }
  }
};
